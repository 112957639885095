import React, { useEffect, useMemo, useState } from 'react';

import { IAppliedJob } from '@/@types/dashboard';
import Container from '@/components/Container';
import { Typography } from '@/components/Typography';
import { Skeleton } from '@/components/ui/skeleton';
import { CANDIDATE_STATUS } from '@/constants/candidate';
import { useGenericQuery } from '@/hooks/useQueryData';
import { cn } from '@/lib/utils';
// import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { getSliderData } from '@/services/dashboard';
import { hasAnyValue } from '@/utils/common';

// import { getProfileCompletePercentage } from '@/services/profile';
// import Vector from '../../../assets/images/Vector.png';
import Banner from '../../../assets/images/banner.webp';

// import ProgressBar from './ProgressBar';

const ProfileStatus = () => {
  const staticText = strings.profileStatusSection;

  const { user } = useAuth();
  const { firstName, personalInfo } = user || {};

  const [fade, setFade] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { data: jobData, isLoading } = useGenericQuery(['applied-jobs'], () =>
    getSliderData(),
  );
  const data = Array.isArray(jobData)
    ? jobData.filter((item) => item.status !== CANDIDATE_STATUS.REJECTED)
    : [];

  // const { data } = useGenericQuery(['complete-profile-percentage'], () =>
  //   getProfileCompletePercentage(),
  // );

  // {

  const filteredPersonalInfo = useMemo(() => {
    return Object.fromEntries(
      Object.entries(user?.personalInfo || {}).filter(([key]) => {
        return key !== 'avatar';
      }),
    );
  }, [user?.personalInfo]);

  const isProfileIncomplete =
    !hasAnyValue(filteredPersonalInfo) ||
    !user?.personalInfo?.resume ||
    !user.personalInfo?.department ||
    !hasAnyValue(user?.academicHistory);

  const RenderProfileMessage = () => (
    <>
      {isProfileIncomplete ? (
        <>
          {staticText.bannerText}
          <p className='text-primaryGreen font-bold'>{staticText.getStarted}</p>
        </>
      ) : (
        <>
          {staticText.noDataMessage}
          <p className='text-primaryGreen font-bold'>
            {staticText.noDataMessageGreen}
          </p>
        </>
      )}
    </>
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true); // Trigger fade-out before updating
      setTimeout(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % (data as IAppliedJob[])?.length,
        );
        setFade(false); // Reset fade to trigger fade-in
      }, 500); // Wait for the fade-out to finish (500ms)
    }, 5000);
    return () => clearInterval(interval); // Clean up on unmount
  }, [data]);

  const getTitle = (sex: string) => {
    if (sex === 'male' || sex === 'Male') {
      return 'Mr.';
    }
    if (sex === 'female' || sex === 'Female') {
      return 'Ms.';
    }
    return '';
  };

  return (
    <Container className='h-fit relative overflow-hidden md:px-4 md:py-3'>
      {/* Video background */}
      <video
        autoPlay
        loop
        muted
        className='absolute top-0 left-0 w-full h-full object-cover z-0'
      >
        <source
          src={require('../../../assets/images/heroSection.mp4')}
          type='video/mp4'
        />
      </video>
      <div className='absolute top-0 left-0 w-full h-full bg-blue-500 opacity-80 z-10 bg-gradient-to-r from-blueMoon via-hydra to-bracingBlue' />
      <img className='absolute top-0 left-0 w-full h-full ' src={Banner} />
      <div className='relative z-20 flex justify-center flex-col items-center'>
        {isLoading ? null : (
          <Typography className='text-white md:text-base font-bold'>
            {staticText.hello},
          </Typography>
        )}
        {isLoading ? (
          <div className='flex w-full items-center justify-center my-1'>
            <Skeleton className='h-6 w-2/3 opacity-40' />
          </div>
        ) : (
          <Typography
            className={cn(
              'text-white text-lg md:text-2xl block font-bold md:w-2/3 xl:w-3/4 text-center',
              {
                'text-lg md:text-xl my-1': !data,
              },
            )}
          >
            {getTitle(personalInfo?.sex || '')}{' '}
            <span className='capitalize'>{firstName}</span>
            {Array.isArray(data) && data.length > 0
              ? staticText.message
              : RenderProfileMessage()}
          </Typography>
        )}
      </div>
      <div className='text-center relative z-20'>
        {isLoading && (
          <div className='flex items-center flex-col gap-2 mt-2'>
            <Skeleton className='w-52 h-5 flex opacity-40' />
            <Skeleton className='w-40 h-4 flex opacity-40' />
          </div>
        )}
        {!isLoading && Array.isArray(data) && data.length > 0 && (
          <>
            <Typography
              className={cn(
                'text-lg md:text-xl text-primaryGreen block font-bold transition-opacity duration-500',
                {
                  'opacity-0': fade,
                  'opacity-100': !fade,
                },
              )}
            >
              {data[currentIndex]?.jobTitle}
            </Typography>
            <Typography
              className={cn(
                'text-white text-sm md:text-base block font-bold transition-opacity duration-500',
                {
                  'opacity-0': fade,
                  'opacity-100': !fade,
                },
              )}
            >
              <span className='text-white'>{staticText.joStatus}: </span>
              <span className='text-primaryGreen'>
                {data[currentIndex]?.status}
              </span>
            </Typography>
          </>
        )}
      </div>
    </Container>
    // <Container className='h-fit relative bg-gradient-to-r from-seaBlue to-seaBlueDark md:px-4 md:py-3'>
    //   <img src={Vector} className='absolute bottom-0' alt='Background' />
    //   <div className='grid sm:grid-cols-3 gap-2'>
    //     <div className='flex items-center gap-2'>
    //       <ProfileAvatar
    //         name={`${firstName} ${lastName}`}
    //         src={(avatar as string) || ''}
    //         className='md:size-16 size-11'
    //       />
    //       <div className='flex flex-col'>
    //         <Typography className='md:text-sm text-white'>
    //           {staticText.welcome}
    //         </Typography>
    //         <Typography className='text-white font-bold' variant='xl'>
    //           {`${firstName} ${lastName}`}
    //         </Typography>
    //       </div>
    //     </div>
    //     <div className='col-span-2 sm:flex justify-between'>
    //       <div>
    //         <Typography className='text-white md:text-sm'>
    //           {staticText.message}
    //         </Typography>
    //         <ProgressBar progress={(data as number) || 0} />
    //       </div>
    //       <div className='absolute sm:static top-4 right-4'>
    //         <Button
    //           variant='outline'
    //           size='icon'
    //           className='sm:size-7 size-5 p-0 rounded border border-greyWhite m-1'
    //           onClick={handleNavigate}
    //         >
    //           <GoLinkExternal className='size-4 sm:size-5 text-primary' />
    //         </Button>
    //       </div>
    //     </div>
    //   </div>
    //   <div className='mt-4 bg-blue h-auto p-2 lg:h-fit lg:py-2 mx-auto flex flex-wrap  rounded-md '>
    //     {STEPPER_STEPS.map((item, index, arr) => (
    //       <React.Fragment key={index}>
    //         <Typography className='h-full mx-1 sm:mx-auto px-1 text-white md:text-sm font-bold'>
    //           {item}
    //         </Typography>
    //         {index < arr.length - 1 && (
    //           <Separator orientation='vertical' className='h-5' />
    //         )}
    //       </React.Fragment>
    //     ))}
    //   </div>
    // </Container>
  );
};

export default ProfileStatus;
