import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { useLocation } from 'react-router-dom';

import { INPUT_ERRORS, PASSWORD_VALIDATIONS_POINTS } from '@/constants';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message';

import { cn } from 'src/lib/utils';

import { Input } from './ui/input';
import TooltipComponent from './Tooltip';
import { Typography } from './Typography';

interface FormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  placeholder?: string;
  labelClassName?: string;
  containerClassName?: string;
  name: string;
  value?: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean;
  errors?: FieldErrors;
  hideIcon?: boolean;
  hideInfoIcon?: boolean;
  type?: string;
  isHeightOrWeight?: boolean;
  triggerFocus?: boolean;
  focusDependency?: boolean[];
}

const FormField: React.FC<FormFieldProps> = ({
  title,
  placeholder,
  name,
  value,
  labelClassName,
  containerClassName,
  onChange,
  isRequired,
  errors,
  hideIcon = false,
  hideInfoIcon = false,
  type = 'text',
  isHeightOrWeight = false,
  triggerFocus = false,
  focusDependency = [],
  ...props
}) => {
  const location = useLocation();
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputType, setInputType] = useState(type);
  const [hidePassword, setHidePassword] = useState(type === 'password');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const togglePasswordVisibility = () => {
    setHidePassword(!hidePassword);
    setInputType((prev) => (prev === 'password' ? 'text' : 'password'));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = Number(inputValue);
    setErrorMessage(null);
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
    if (isHeightOrWeight) {
      if (numericValue > 250) {
        setErrorMessage(INPUT_ERRORS.HEIGHT_AND_WEIGHT.validate);
        setTimeoutId(setTimeout(() => setErrorMessage(null), 1000));
        return;
      }
    }
    onChange(event);
  };

  useEffect(() => {
    if (
      triggerFocus &&
      location.pathname === ROUTES.SIGNUP &&
      inputRef.current
    ) {
      inputRef.current.focus();
    }
  }, [name, location.pathname, triggerFocus, ...focusDependency]);

  return (
    <>
      <label className={cn('flex flex-col ', { 'my-2': title })}>
        <div className='flex justify-between items-center pr-1'>
          {title ? (
            <Typography
              className={cn(
                'flex md:text-sm capitalize font-semibold',
                labelClassName,
              )}
            >
              {title}
              {isRequired ? (
                <span className='text-redColor text-xl ml-1'>*</span>
              ) : null}
            </Typography>
          ) : null}
          {type === 'password' && !hideInfoIcon ? (
            <TooltipComponent>
              <div className='flex flex-col gap-y-0.5'>
                {PASSWORD_VALIDATIONS_POINTS.map((item) => (
                  <Typography
                    key={item}
                    className='flex text-xs md:text-xs gap-1.5'
                  >
                    <IoMdCheckmarkCircleOutline className='text-xs w-4 mt-1 text-greenRevolution' />
                    {item.replace('*', '')}
                  </Typography>
                ))}
              </div>
            </TooltipComponent>
          ) : null}
        </div>
        <div className='relative'>
          <Input
            ref={inputRef}
            name={name}
            placeholder={placeholder}
            className={cn(
              'h-12 text-[15px] disabled:bg-greyWhite',
              { 'pr-10': type === 'password' },
              containerClassName,
            )}
            value={value || ''}
            onChange={handleChange} // Use the custom handleChange
            maxLength={50}
            min={0}
            type={inputType}
            {...props}
          />
          {type === 'password' && !hideIcon ? (
            <button
              onClick={togglePasswordVisibility}
              className='absolute right-4 top-4'
              type='button'
              aria-label={
                hidePassword
                  ? strings.common.hidePassword
                  : strings.common.showPassword
              }
            >
              {hidePassword ? <FiEyeOff size={17} /> : <FiEye size={17} />}
            </button>
          ) : null}
        </div>
        {errorMessage && (
          <p className='text-redColor text-xs'>{errorMessage}</p> // Display error message
        )}
        <HookFormErrorMessage
          errors={errors}
          name={String(name)}
          render={({ message }) => (
            <p className='text-redColor text-xs'>{message}</p>
          )}
        />
      </label>
    </>
  );
};

export default FormField;
