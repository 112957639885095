import React, { useEffect, useRef, useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { HiOutlineTrash } from 'react-icons/hi';

import { IUser } from '@/@types/auth';
import { ITrainingSeminar } from '@/@types/profile';
import Container from '@/components/Container';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { TRAINING_SEMINAR_FIELDS } from '@/constants/profile';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useFormContext } from '@/provider/FormProvider/index';
import { trainingSeminarsStep } from '@/services/profile';
import { hasAnyValue } from '@/utils/common';
import { Separator } from '@radix-ui/react-separator';

const TrainingSeminar = () => {
  const { profile: staticText, common } = strings;
  const { stepThree, handleBack, handleSkip, handleNext, setData } =
    useFormContext();
  const { user, setUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const hasInitialized = useRef(false);

  const {
    control,
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
  } = useForm<ITrainingSeminar>({
    mode: 'all',
    defaultValues: {
      trainingSeminars:
        user?.trainingSeminars || stepThree?.trainingSeminars || [],
    },
  });

  const { fields, append, remove, replace } = useFieldArray<ITrainingSeminar>({
    control,
    name: 'trainingSeminars',
  });

  const newSectionRef = useRef<HTMLDivElement | null>(null);

  const deleteSection = (index: number) => {
    remove(index);
  };

  const addNewSection = () => {
    append({ courseTitle: '', inclusiveDates: '', venue: '' });
    setTimeout(() => {
      newSectionRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }, 100);
  };

  useEffect(() => {
    if (!hasInitialized.current && fields.length === 0) {
      addNewSection();
      hasInitialized.current = true;
    }
  }, [fields, append]);

  const trainingSeminarMutation = useGenericMutation<
    ITrainingSeminar,
    IUser | boolean
  >(trainingSeminarsStep, {
    onSuccess: (result) => {
      if (typeof result !== 'boolean') {
        setData({
          step: 3,
          data: {
            trainingSeminars: result?.trainingSeminars,
          },
        });
        handleNext();
        setUser({
          ...user,
          trainingSeminars: result?.trainingSeminars,
        } as IUser);
        setIsEdit(false);
      }
    },
  });

  const onSubmit: SubmitHandler<ITrainingSeminar> = (data) => {
    if (
      isDirty &&
      Object.keys(dirtyFields).some((fieldName) => {
        return (
          data[fieldName as keyof ITrainingSeminar] !== undefined ||
          data[fieldName as keyof ITrainingSeminar] !== null
        );
      })
    ) {
      trainingSeminarMutation.mutate(data);
    } else {
      handleNext();
    }
  };

  const handleEditOrDeleteButton = () => {
    if (isEdit) {
      replace(user?.trainingSeminars || stepThree?.trainingSeminars || []); // Restores original API sections
    }
    setIsEdit(!isEdit);
  };

  return (
    <>
      <Container className='rounded-t-none border-t-0 p-4 w-[95%] mx-auto'>
        <div className='mt-10 md:mt-20'>
          <div className='flex justify-end'>
            {hasAnyValue(user?.trainingSeminars) ? (
              <Button
                variant='link'
                className={cn('w-auto h-auto p-1 text-base', {
                  'text-tomatoRed': isEdit,
                })}
                onClick={handleEditOrDeleteButton}
              >
                {isEdit ? strings.common.cancel : strings.common.edit}
              </Button>
            ) : null}
          </div>
          <div className='flex justify-between'>
            <Typography variant='heading' className='md:text-2xl'>
              {staticText.trainingSeminars}
            </Typography>
            {!hasAnyValue(user?.trainingSeminars) || isEdit ? (
              <div onClick={addNewSection}>
                <Typography className='text-primary font-semibold cursor-pointer flex items-center gap-1'>
                  <FaPlus />
                  {staticText.buttons.addNew}
                </Typography>
              </div>
            ) : null}
          </div>

          {fields.map((field, sectionIndex) => (
            <div key={field.id} ref={newSectionRef}>
              {sectionIndex !== 0 && (
                <>
                  <Separator className='mt-5 mb-2' />
                  {!hasAnyValue(user?.trainingSeminars) || isEdit ? (
                    <div className='flex justify-end'>
                      <div onClick={() => deleteSection(sectionIndex)}>
                        <Typography className='text-tomatoRed mb-1 font-semibold cursor-pointer flex items-center gap-1'>
                          <HiOutlineTrash />
                          {staticText.buttons.delete}
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                </>
              )}
              <div className='md:grid md:grid-cols-3 gap-x-4 mt-4'>
                {TRAINING_SEMINAR_FIELDS.map((fieldConfig, index) => (
                  <div key={`${fieldConfig.name}_${index}`}>
                    <Controller
                      control={control}
                      name={
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        `trainingSeminars.${sectionIndex}.${fieldConfig.name}` as any
                      }
                      rules={{
                        required: `${fieldConfig.title} ${common.isRequired}`,
                      }}
                      render={({ field: { onChange, value, name } }) => (
                        <FormField
                          title={fieldConfig.title}
                          placeholder={common.typeHere}
                          name={name}
                          value={value as string | number}
                          onChange={onChange}
                          errors={errors}
                          labelClassName='font-bold md:text-base mb-3'
                          disabled={
                            !isEdit && hasAnyValue(user?.trainingSeminars)
                          }
                          isRequired
                        />
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Container>

      <div className='w-[95%] mx-auto py-3 flex justify-between'>
        <div onClick={() => handleSkip(3)}>
          <Typography className='text-primary underline font-semibold cursor-pointer'>
            {staticText.buttons.skip}
          </Typography>
        </div>
        <div className='flex gap-3'>
          <Button
            variant='outline'
            className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
            onClick={handleBack}
            disabled={trainingSeminarMutation.status === 'pending'}
          >
            {staticText.buttons.back}
          </Button>
          <Button
            className='sm:w-44 md:w-32'
            onClick={handleSubmit(onSubmit)}
            disabled={
              trainingSeminarMutation.status === 'pending' ||
              (!isDirty && isEdit)
            }
            loading={trainingSeminarMutation.status === 'pending'}
          >
            {isEdit ? staticText.buttons.update : staticText.buttons.next}
          </Button>
        </div>
      </div>
    </>
  );
};

export default TrainingSeminar;
