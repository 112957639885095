import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Lottie from 'lottie-react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';

import { IPaginationResponse } from '@/@types';
import { IJobs } from '@/@types/dashboard';
import { Typography } from '@/components/Typography';
import { DASHBOARD_TABS } from '@/constants/dashboard';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { getAllJobs } from '@/services/auth';
import { formatCurrency } from '@/utils/common';
import { getLocalDate } from '@/utils/dateTime';
import { useInfiniteQuery } from '@tanstack/react-query';

import NoDataAnimation from '../../../assets/animations/noData.json';

import JobCard from './SignupJobCard';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const JobSlider = () => {
  const staticText = strings.dashboard;

  const [jobs, setJobs] = useState<IJobs[]>([]);
  const { selectedJob, setSelectedJob, setShowToast } = useAuth();
  const [activeIndex, setActiveIndex] = useState(0);

  const { data, hasNextPage, isLoading, fetchNextPage } = useInfiniteQuery({
    queryKey: ['all-job-list'],
    queryFn: ({ pageParam = 1 }) =>
      getAllJobs(DASHBOARD_TABS.ALL, pageParam.toString()),
    initialPageParam: 1,
    getNextPageParam: (data, pages) => {
      if (typeof data === 'object' && pages.length < data.totalPages) {
        return pages.length + 1;
      }
      return undefined;
    },
  });

  const jobData = useMemo(
    () =>
      jobs.map((item) => ({
        _id: item?._id,
        title: item?.jobTitle ?? '-',
        company: item?.companyDetails?.name ?? '-',
        date: getLocalDate(item?.createdAt, 'MMM D, YYYY') || '-',
        description: item?.jobDescription ?? '-',
        hired: `${item?.hiredCount || '0'}/${item?.numberOfHC || '0'}`,
        jobType: item?.jobType ?? '-',
        salary: formatCurrency(item?.rate) ?? '-',
        location:
          item?.assignmentCountry && item?.assignmentCity
            ? `${item?.assignmentCity}, ${item?.assignmentCountry}`
            : '-',
      })),
    [jobs],
  );

  useEffect(() => {
    if (data) {
      const newJobs =
        data.pages.flatMap(
          (page) => (page as IPaginationResponse<IJobs>).results,
        ) || [];
      setJobs(newJobs);
    }
  }, [data]);

  const handleSlideChange = (swiper: SwiperClass) => {
    setActiveIndex(swiper.activeIndex);
    if (swiper.activeIndex === jobs.length - 3 && hasNextPage && !isLoading) {
      fetchNextPage();
    }
    if (selectedJob) {
      setSelectedJob(null);
      setShowToast(false);
    }
  };

  const swiperRef = useRef<SwiperClass | null>(null);

  useEffect(() => {
    if (swiperRef.current) {
      if (selectedJob) {
        swiperRef.current.autoplay?.stop();
      } else {
        swiperRef.current.autoplay?.start();
      }
    }
  }, [selectedJob]);

  useEffect(() => {
    if (data?.pages?.length) {
      setActiveIndex(0);
    }
  }, [data]);

  return isLoading || jobData.length ? (
    <div className='custom-swiper-container relative'>
      <Swiper
        effect='coverflow'
        grabCursor={false}
        loop={false}
        centeredSlides={true}
        initialSlide={isLoading ? 1 : 0}
        spaceBetween={window.innerWidth < 1200 ? 30 : 80}
        slidesPerView='auto'
        allowTouchMove={true}
        slideToClickedSlide={true}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        autoplay={
          !selectedJob && jobData.length > 1
            ? { delay: 2200, disableOnInteraction: false }
            : undefined
        }
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
          slideShadows: false,
        }}
        navigation={
          !isLoading
            ? { nextEl: '.arrow-right', prevEl: '.arrow-left' }
            : undefined
        }
        onSlideChange={handleSlideChange}
        breakpoints={{
          0: {
            slidesPerView: 1.2,
          },
          1000: {
            slidesPerView: 1.3,
          },
          1200: {
            slidesPerView: 1.8,
          },
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className='swiper custom-swiper'
      >
        {isLoading
          ? Array.from({ length: 3 }).map((_, index) => (
              <SwiperSlide key={index}>
                <JobCard loading={true} />
              </SwiperSlide>
            ))
          : jobData.map((projects, index) => (
              <SwiperSlide key={index}>
                <JobCard slide={projects} />
              </SwiperSlide>
            ))}
        <button
          className={cn('arrow-left arrow', {
            'opacity-60': isLoading,
            'opacity-60 cursor-not-allowed': isLoading || activeIndex === 0,
          })}
        >
          <MdKeyboardArrowLeft className='size-3 md:size-10' />
        </button>
        <button
          className={cn('arrow-right arrow', {
            'opacity-60': isLoading,
            'opacity-60 cursor-not-allowed':
              activeIndex + 1 ===
              data?.pages.flatMap(
                (page) => (page as IPaginationResponse<IJobs>)?.totalJobs,
              )?.[0],
          })}
        >
          <MdKeyboardArrowRight className='size-3 md:size-10' />
        </button>
      </Swiper>
    </div>
  ) : (
    <div className='flex flex-col justify-center items-center'>
      <Lottie
        animationData={NoDataAnimation}
        loop={true}
        className=' w-3/4 md:w-1/2 '
      />
      <Typography className='text-center w-2/3 text-secondary md:text-lg'>
        {staticText.noJobsText}
      </Typography>
    </div>
  );
};

export default JobSlider;
