import React from 'react';

import { Typography } from '@/components/Typography';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { ROUTES } from '@/routes';

// import AuthImage from '../../../assets/images/authImage.webp';
import Logo from '../../../assets/images/logo.svg';

import JobSlider from './JobSlider';

interface IAuthWrapper {
  children: React.ReactNode;
}

const AuthWrapper: React.FC<IAuthWrapper> = ({ children }) => {
  const staticText = strings.authWrapperText;

  return (
    <div className='md:grid flex flex-col md:grid-cols-2 md:grid-rows-1 md:h-screen'>
      {/* <div className='bg-gradient-bg md:flex flex-col justify-between overflow-hidden hidden'>
        <div className='px-10 md:w-3/4 h-full flex items-center py-16'>
          <Typography variant='heading' className='md:text-4xl text-white'>
            {staticText.title}
          </Typography>
        </div>
        {<img src={AuthImage} className='w-[90%]' />}
      </div> */}
      <div className='bg-gradient-bg flex flex-col md:gap-5 py-2 md:py-5 justify-center relative'>
        <div className='absolute shadow-[inset_-0px_-0px_90px] shadow-blue inset-0 z-10 pointer-events-none' />
        <div className='flex justify-center items-center md:px-20 px-2'>
          <Typography className='text-base md:text-4xl font-bold text-white text-center'>
            {staticText.jobTitle}
          </Typography>
        </div>
        <JobSlider />
      </div>
      <div
        className={cn(
          'w-11/12 lg:w-3/5 mx-auto pt-6 md:pt-12 flex flex-col justify-between pb-3 md:pb-6 overflow-scroll px-1',
          { 'lg:w-4/5': location.pathname.includes(ROUTES.SIGNUP) },
        )}
      >
        <div className='my-auto'>
          <img src={Logo} alt='Logo' className='mb-2 md:mb-5' />
          {children}
        </div>
        <Typography className='text-center w-full pt-6'>
          © {new Date().getFullYear()} {staticText.footerText}
        </Typography>
      </div>
    </div>
  );
};

export default AuthWrapper;
