import toast from 'react-hot-toast';

import { IPaginationResponse } from '@/@types';
import { IJobs } from '@/@types/dashboard';
import { WorkExperience } from '@/@types/profile';
import { API } from '@/api/ApiInstance';
import { REST_SUB_URL } from '@/constants/urls';
import { buildQueryParams } from '@/utils/common';

import {
  ForgotPasswordEmailField,
  IGoogleVerifyResponse,
  ILoginRequest,
  ILoginResponse,
  IOtpFields,
  IResetPasswordFields,
  ISignUpResponse,
  IUser,
  OtpFieldsResponseType,
  ResendVerificationEmail,
} from '../@types/auth';

export interface ISignupPayload {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  civilStatus?: string;
  contactNo?: string;
  country?: string;
  city?: string;
  block?: string;
  expectedSalary?: number;
  college?: string;
  collegeYearCovered?: string;
  collegeCourse?: string;
  vocational?: string;
  vocationalYearCovered?: string;
  vocationalCourse?: string;
  highSchool?: string;
  highSchoolYearCovered?: string;
  highSchoolCourse?: string;
  elementary?: string;
  elementaryYearCovered?: string;
  elementaryCourse?: string;
  course?: string;
  yearCovered?: string;
  age?: number;
  workExperiences?: WorkExperience[];
}

export const signup = async (
  payload: ISignupPayload,
): Promise<string | boolean> => {
  const response = await API.Post<ISignupPayload, ISignUpResponse>(
    REST_SUB_URL.SIGNUP,
    payload,
  );

  if (response.status && response?.data) {
    return response?.message;
  }
  toast.error(response.message);
  return false;
};

export const googleVerifySignup = async (): Promise<IGoogleVerifyResponse> => {
  const response = await API.Post<undefined, IGoogleVerifyResponse>(
    REST_SUB_URL.GOOGLE_VERIFY,
  );

  if (response.status && response?.data) {
    return response.data;
  }
  throw new Error(response.message);
};

export const verifySignupEmail = async (
  payload: IOtpFields,
): Promise<OtpFieldsResponseType> => {
  const response = await API.Post<IOtpFields, OtpFieldsResponseType>(
    REST_SUB_URL.VERIFY_EMAIL,
    payload,
  );
  if (response.status && response?.data) {
    return response.data;
  }
  throw new Error(response.message);
};

export const resendVerifyEmail = async (
  payload: ResendVerificationEmail,
): Promise<boolean> => {
  const response = await API.Post<ResendVerificationEmail, boolean>(
    REST_SUB_URL.RESEND_VERIFICATION_EMAIL,
    payload,
  );

  if (response.status) {
    return true;
  }
  throw new Error(response.message);
};

export const login = async (
  payload: ILoginRequest,
): Promise<ILoginResponse | boolean> => {
  const response = await API.Post<ILoginRequest, ILoginResponse>(
    REST_SUB_URL.LOGIN,
    payload,
  );
  if (response.status && response?.data) {
    return response?.data;
  }
  toast.error(response.message);
  return false;
};

export const forgotPassword = async (
  payload: ForgotPasswordEmailField,
): Promise<IUser | boolean> => {
  const response = await API.Post<ForgotPasswordEmailField, IUser | boolean>(
    REST_SUB_URL.FORGOT_PASSWORD,
    payload,
  );
  if (response.status && response?.data) {
    return response.data;
  }
  toast.error(response.message);
  return false;
};

export const verifyOtp = async (
  payload: IOtpFields,
): Promise<OtpFieldsResponseType> => {
  const response = await API.Post<IOtpFields, OtpFieldsResponseType>(
    REST_SUB_URL.VERIFY_RESET_PASSWORD,
    payload,
  );
  if (response.status && response?.data) {
    return response.data;
  }
  throw new Error(response.message);
};

export const resetPassword = async (
  payload: IResetPasswordFields,
): Promise<string | boolean> => {
  const response = await API.Post<IResetPasswordFields, boolean>(
    REST_SUB_URL.RESET_PASSWORD,
    payload,
  );
  if (response.status) {
    return response.message;
  }
  toast.error(response.message);
  return false;
};

export const getAllJobs = async (
  type: string,
  page?: string,
): Promise<IPaginationResponse<IJobs> | boolean> => {
  const queryParams = buildQueryParams({
    type,
    page,
  });
  const response = await API.Get<IPaginationResponse<IJobs>>(
    `${REST_SUB_URL.GET_ALL_JOBS_FOR_PUBLIC}?${queryParams}`,
  );

  if (response.status && response?.data) {
    return response.data as IPaginationResponse<IJobs>;
  }

  toast.error(response.message);
  return false;
};
