import React from 'react';

import { Typography } from '@/components/Typography';

interface IAuthHeadingsAndDesc {
  title: string;
  desc: string;
}

const AuthHeadingsAndDesc: React.FC<IAuthHeadingsAndDesc> = ({
  title,
  desc,
}) => {
  return (
    <div className='pb-3 md:pb-6'>
      <Typography variant='heading' className='font-bold block md:pb-4'>
        {title}
      </Typography>
      <Typography variant='subheading' className='font-semibold text-mouseGrey'>
        {desc}
      </Typography>
    </div>
  );
};

export default AuthHeadingsAndDesc;
