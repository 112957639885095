import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormWatch,
} from 'react-hook-form';

import { ISignUpFields } from '@/@types/auth';
import { IAcademicHistory } from '@/@types/profile';
import FormField from '@/components/FormField';
import { INPUT_ERRORS } from '@/constants';
import { ACADEMIC_HISTORY_FIELDS } from '@/constants/profile';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { hasAnyValue } from '@/utils/common';

interface IAcademicHistoryFields {
  watch: UseFormWatch<ISignUpFields | IAcademicHistory>;
  control: Control<ISignUpFields | IAcademicHistory>;
  errors: FieldErrors<ISignUpFields>;
  isEdit?: boolean;
  isSignUpScreen?: boolean;
}

const AcademicHistoryFields: React.FC<IAcademicHistoryFields> = ({
  watch,
  control,
  errors,
  isEdit,
  isSignUpScreen,
}) => {
  const { common } = strings;
  const { user } = useAuth();

  const isSectionFilled = (fields: (string | number)[]) =>
    fields.some((value) => !!value); // Check if any field in the section has a value

  const collegeFields = watch([
    'college',
    'collegeYearCovered',
    'collegeCourse',
  ]);

  const vocationalFields = watch([
    'vocational',
    'vocationalYearCovered',
    'vocationalCourse',
  ]);

  const highSchoolFields = watch([
    `${isSignUpScreen ? 'additionalInfo.academicHistory.' : ''}highSchool`,
    `${isSignUpScreen ? 'additionalInfo.academicHistory.' : ''}highSchoolYearCovered`,
    `${isSignUpScreen ? 'additionalInfo.academicHistory.' : ''}highSchoolCourse`,
  ]);

  const elementaryFields = watch([
    `${isSignUpScreen ? 'additionalInfo.academicHistory.' : ''}elementary`,
    `${isSignUpScreen ? 'additionalInfo.academicHistory.' : ''}elementaryYearCovered`,
    `${isSignUpScreen ? 'additionalInfo.academicHistory.' : ''}elementaryCourse`,
  ]);

  return (
    <div
      className={cn('md:grid md:grid-cols-3 gap-x-4', {
        'md:grid-cols-2 sm:grid-cols-2 sm:grid': isSignUpScreen,
      })}
    >
      {ACADEMIC_HISTORY_FIELDS.map((field, index) => (
        <React.Fragment key={`${field.name}_${index}`}>
          <div className='block'>
            <Controller
              control={control}
              name={
                `${isSignUpScreen ? 'additionalInfo.academicHistory.' : ''}${field.name}` as keyof IAcademicHistory
              }
              rules={{
                validate: (value) => {
                  const isHighSchoolSectionFilled =
                    isSectionFilled(highSchoolFields);
                  const isElementaryFieldsFilled =
                    isSectionFilled(elementaryFields);

                  const shouldValidate =
                    field.name.includes('college') ||
                    field.name.includes('vocational') ||
                    (field.name.includes('highSchool') &&
                      isHighSchoolSectionFilled) ||
                    (field.name.includes('elementary') &&
                      isElementaryFieldsFilled);

                  if (
                    shouldValidate &&
                    field.name.toLowerCase().includes('yearcovered')
                  ) {
                    if (!value) {
                      return `${field.title} ${common.isRequired}`;
                    }
                    if (/^[+-]/.test(value)) {
                      return INPUT_ERRORS.YEAR.startLetterValidat;
                    }
                    const yearRangeRegex = /^\d{4}-\d{4}$/;
                    if (!yearRangeRegex.test(value)) {
                      return INPUT_ERRORS.YEAR.formatValidate;
                    }
                    const [startYear, endYear] = value.split('-').map(Number);
                    if (startYear && endYear && startYear > endYear) {
                      return INPUT_ERRORS.YEAR.rangeValidate;
                    }
                  }

                  // Validate section-based fields
                  const isCollegeSectionFilled = isSectionFilled(collegeFields);
                  const isVocationalSectionFilled =
                    isSectionFilled(vocationalFields);

                  if (field.name.includes('college')) {
                    return (
                      !!value ||
                      (isCollegeSectionFilled
                        ? `${field.title} is required`
                        : `${field.title} is required`)
                    );
                  }

                  if (field.name.includes('vocational')) {
                    return (
                      !!value ||
                      (isVocationalSectionFilled
                        ? `${field.title} is required`
                        : `${field.title} is required`)
                    );
                  }

                  if (
                    (field.name.includes('highSchool') &&
                      isSectionFilled(highSchoolFields)) ||
                    (field.name.includes('elementary') &&
                      isSectionFilled(elementaryFields))
                  ) {
                    return !!value || `${field.title} ${common.isRequired}`;
                  }

                  return true;
                },
              }}
              render={({ field: { onChange, value, name } }) => (
                <FormField
                  title={field.title}
                  placeholder={
                    field.name.toLowerCase().includes('yearcovered')
                      ? '2018-2021'
                      : common.typeHere
                  }
                  name={name}
                  value={value}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    if (field.name.toLowerCase().includes('yearcovered')) {
                      inputValue = inputValue.replace(/[^0-9-]/g, ''); // Allow only numbers and "-"
                      // Prevent starting with non-numeric characters
                      if (!/^\d/.test(inputValue)) {
                        onChange('');
                        return;
                      }
                      // Prevent multiple hyphens or incorrect placement
                      const parts = inputValue.split('-');
                      if (parts.length > 2) {
                        return;
                      }
                      // Ensure each part is at most 4 digits
                      if (parts.some((part) => part.length > 4)) {
                        return;
                      }
                    }
                    onChange(inputValue);
                  }}
                  errors={errors}
                  labelClassName='font-bold md:text-base mb-1.5 sm:mb-3'
                  isRequired={
                    field.name.includes('college') ||
                    field.name.includes('vocational')
                  }
                  disabled={!isEdit && hasAnyValue(user?.academicHistory)}
                />
              )}
            />
          </div>
          {(index === 2 || index === 5 || index === 8) && isSignUpScreen ? (
            <div className='mt-6 sm:mt-0' />
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AcademicHistoryFields;
