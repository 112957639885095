import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormWatch,
} from 'react-hook-form';

import { ISignUpFields } from '@/@types/auth';
import { IAcademicHistory } from '@/@types/profile';
import FormField from '@/components/FormField';
import { Separator } from '@/components/separator';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { INPUT_ERRORS } from '@/constants';
import { strings } from '@/locales';
import WorkExperience from '@/pages/Profile/Stepper/WorkExperience';

import AcademicHistoryFields from '../components/AcademicHistoryFields';

interface IAdditionalInfoFields {
  control: Control<ISignUpFields>;
  errors: FieldErrors<ISignUpFields>;
  watch: UseFormWatch<ISignUpFields>;
  loading: boolean;
  handleInfoSectionBtn: () => void;
}

const AdditionalInfoFields: React.FC<IAdditionalInfoFields> = ({
  control,
  errors,
  watch,
  loading,
  handleInfoSectionBtn,
}) => {
  const { signUp: staticText, common, profile } = strings;

  return (
    <div className='col-span-2 space-y-6'>
      <Separator className='mt-5' />
      <div className='flex justify-between'>
        <Typography variant='subheading' className='font-bold md:text-xl'>
          {staticText.additionalInfo}
        </Typography>
        <Button
          className='text-sm px-1 md:px-1 w-fit h-fit py-0 text-red-500'
          variant='link'
          onClick={handleInfoSectionBtn}
          loading={loading}
          disabled={loading}
        >
          {common.cancel}
        </Button>
      </div>
      <Controller
        control={control}
        name='additionalInfo.expectedSalary'
        rules={{
          validate: (value) => {
            const sanitizedValue = String(value).trim();
            if (!sanitizedValue || !/^\d+$/.test(sanitizedValue)) {
              return INPUT_ERRORS.EXPECTED_SALARY.required;
            }
            if (Number(sanitizedValue) <= 0) {
              return INPUT_ERRORS.EXPECTED_SALARY.greaterThanZero;
            }

            return true;
          },
        }}
        render={({ field: { onChange, value, name } }) => (
          <FormField
            title={staticText.expectedSalary}
            labelClassName='font-bold md:text-base mb-1.5 sm:mb-3'
            placeholder={`${common.enter} ${staticText.expectedSalary}`}
            name={name}
            value={value}
            isRequired
            onChange={(e) => {
              let sanitizedValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
              // Allow single zero but remove leading zeros from other numbers
              if (sanitizedValue.length > 1) {
                sanitizedValue = sanitizedValue.replace(/^0+/, '');
              }
              if (sanitizedValue.length <= 20) {
                onChange(sanitizedValue);
              }
            }}
            errors={errors}
            type='text'
            inputMode='numeric'
          />
        )}
      />

      <div>
        <Typography className='text-lg md:text-xl font-semibold'>
          {profile.academicHistory}
        </Typography>
        <AcademicHistoryFields
          watch={watch as UseFormWatch<ISignUpFields | IAcademicHistory>}
          control={control as Control<ISignUpFields | IAcademicHistory>}
          errors={errors}
          isSignUpScreen
        />
      </div>
      <WorkExperience
        watch={watch as UseFormWatch<IAcademicHistory | ISignUpFields>}
        control={control as Control<IAcademicHistory | ISignUpFields>}
        errors={errors}
        isSignUpScreen
      />
    </div>
  );
};

export default AdditionalInfoFields;
