/* eslint-disable camelcase */
import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { addMonths, getYear, setYear, subMonths, subYears } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';

import { buttonVariants } from '@/components/ui/button';

import { cn } from 'src/lib/utils';

type SelectSingleEventHandler = (
  day: Date | undefined,
  selectedDay: { selected: boolean },
  triggerDate: Date,
) => void;

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  isBirthDate?: boolean;
} & (
    | { mode?: 'single'; selected?: Date; onSelect?: SelectSingleEventHandler }
    | { mode?: 'multiple' | 'range' | 'default' }
  );

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  isBirthDate = false,
  ...props
}: CalendarProps) {
  const [currentMonth, setCurrentMonth] = React.useState(
    isBirthDate ? subYears(new Date(), 19) : new Date(),
  );

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => addMonths(prevMonth, 1));
  };

  const handlePreviousMonth = () => {
    setCurrentMonth((prevMonth) => subMonths(prevMonth, 1));
  };

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newYear = Number(event.target.value);
    setCurrentMonth((prevMonth) => setYear(prevMonth, newYear));

    if (props.mode === 'single' && props.selected) {
      const updatedDate = setYear(props.selected, newYear);
      props.onSelect?.(updatedDate, { selected: true }, updatedDate);
    }
  };

  // Calculate the min and max years based on `isBirthDate`
  const currentYear = getYear(new Date());
  const maxYear = isBirthDate ? currentYear - 19 : currentYear;
  const minYear = isBirthDate ? currentYear - 100 : 1900;

  // Generate the years array based on the min and max limits
  const years = Array.from(
    { length: maxYear - minYear + 1 },
    (_, index) => maxYear - index,
  );

  return (
    <DayPicker
      month={currentMonth}
      onMonthChange={setCurrentMonth}
      showOutsideDays={showOutsideDays}
      captionLayout='dropdown-years'
      className={cn('p-3', className)}
      classNames={{
        root: 'relative',
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-y-0',
        month: 'space-y-4',
        month_caption: 'flex justify-between items-center pt-1',
        caption_label: 'text-sm font-medium',
        nav: 'absolute flex items-center justify-end w-full pr-5 pt-4',
        button_next: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        button_previous: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        weekdays:
          'flex text-slate-500 rounded-md w-9 font-normal text-[0.8rem] dark:text-slate-400',
        month_grid: 'w-full border-collapse space-y-1',
        weekday: 'mx-[9px]',
        day: cn(
          'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-slate-100/50 [&:has([aria-selected])]:bg-slate-100 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20 dark:[&:has([aria-selected].day-outside)]:bg-slate-800/50 dark:[&:has([aria-selected])]:bg-slate-800 !border-none',
        ),
        day_button: cn(
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        selected:
          '!bg-[#2B8ABC] text-slate-50 rounded-xl font-semibold dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50 dark:hover:text-slate-900 dark:focus:bg-slate-50 dark:focus:text-slate-900',
        today:
          '!bg-slate-300/50 !text-black font-semibold dark:bg-slate-800 dark:text-slate-50',
        outside:
          'day-outside text-slate-500 opacity-50 aria-selected:bg-slate-100/50 aria-selected:text-slate-500 aria-selected:opacity-30 dark:text-slate-400 dark:aria-selected:bg-slate-800/50 dark:aria-selected:text-slate-400',
        disabled: 'text-slate-500 opacity-50 dark:text-slate-400',
        range_middle:
          'aria-selected:bg-slate-100 aria-selected:text-slate-900 dark:aria-selected:bg-slate-800 dark:aria-selected:text-slate-50',
        hidden: 'invisible',
        ...classNames,
      }}
      components={{
        PreviousMonthButton: () => (
          <button onClick={handlePreviousMonth}>
            <ChevronLeft className='h-4 w-4' />
          </button>
        ),
        NextMonthButton: () => (
          <button onClick={handleNextMonth}>
            <ChevronRight className='h-4 w-4' />
          </button>
        ),
        YearsDropdown: () => (
          <div className='relative'>
            <select
              className='w-full sm:w-auto p-2 border rounded-md text-sm'
              value={currentMonth.getFullYear()}
              onChange={handleYearChange}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        ),
      }}
      {...props}
    />
  );
}

Calendar.displayName = 'Calendar';

export { Calendar };
