export enum CANDIDATE_STATUS {
  ACTIVE = 'Active',
  SHORTLISTED = 'Shortlisted',
  ASSESSMENT = 'Assessment',
  INTERVIEWING = 'Interviewing',
  INTERVIEW_CLEARED = 'Interview Cleared',
  ON_HOLD = 'On Hold',
  REJECTED = 'Rejected',
  ENDORSED = 'Endorsed',
  HR_ORIENTATION = 'HR Orientation',
  PRE_DEPLOY = 'Pre-Deploy',
}
export enum DEPLOYMENT_STATE {
  DEPLOYED = 'Deployed',
  BACKEDOUT = 'Backedout',
}
