import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { IUser } from '@/@types/auth';
import BackHeader from '@/components/BackHeader';
import Container from '@/components/Container';
import { useGenericQuery } from '@/hooks/useQueryData';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useFormContext } from '@/provider/FormProvider';
import { ROUTES } from '@/routes';
import { getProfile } from '@/services/profile';
import { hasAnyValue } from '@/utils/common';

import AcademicHistory from './Stepper/AcademicHistory';
import FinalStep from './Stepper/FinalStep';
import PersonalInfo from './Stepper/PersonalInfo';
import SpecialSkills from './Stepper/SpecialSkills';
import TrainingSeminar from './Stepper/TrainingSeminar';
import WorkExperience from './Stepper/WorkExperience';
import Stepper from './Stepper';

const Profile = () => {
  const { currentStep, setCurrentStep } = useFormContext();
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const { data, isLoading } = useGenericQuery(['candidate-profile'], () =>
    getProfile(),
  );

  const filteredOthers = useMemo(() => {
    return Object.fromEntries(
      Object.entries(user?.others || {}).filter(([key]) => {
        return key !== 'documents';
      }),
    );
  }, [user?.others]);

  useEffect(() => {
    if (data && !isLoading) {
      setUser({ ...user, ...(data as IUser) });
    }
  }, [data, isLoading]);

  const filteredPersonalInfo = useMemo(() => {
    return Object.fromEntries(
      Object.entries(user?.personalInfo || {}).filter(([key]) => {
        return key !== 'avatar';
      }),
    );
  }, [user?.personalInfo]);

  useEffect(() => {
    if (!user) return;
    if (
      !hasAnyValue(filteredPersonalInfo) ||
      !user?.personalInfo?.resume ||
      !user?.personalInfo?.department
    ) {
      setCurrentStep(1);
    } else if (!hasAnyValue(user?.academicHistory)) {
      setCurrentStep(2);
    } else if (!hasAnyValue(user?.trainingSeminars)) {
      setCurrentStep(3);
    } else if (!hasAnyValue(user?.workExperiences)) {
      setCurrentStep(4);
    } else if (!user?.skills?.length && !user?.specialSkills?.length) {
      setCurrentStep(5);
    } else if (!hasAnyValue(filteredOthers)) {
      setCurrentStep(6);
    } else {
      setCurrentStep(1);
    }
  }, [user, isLoading]);

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <PersonalInfo />;
      case 2:
        return <AcademicHistory />;
      case 3:
        return <TrainingSeminar />;
      case 4:
        return <WorkExperience />;
      case 5:
        return <SpecialSkills />;
      case 6:
        return <FinalStep />;
      default:
        return null;
    }
  };

  return (
    <>
      {currentStep === 1 ? (
        <div className='px-10'>
          <BackHeader
            title={strings.sidebarRoutes.profile || ''}
            loading={isLoading}
            handleBackBtn={() => navigate(ROUTES.DASHBOARD)}
          />
        </div>
      ) : null}
      <Container className='mt-5 rounded-b-none border-b-0 w-[95%] mx-auto'>
        <Stepper />
      </Container>
      {renderStepContent()}
    </>
  );
};

export default Profile;
