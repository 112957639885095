import React, { useEffect, useRef, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  useFieldArray,
  useForm,
  UseFormWatch,
} from 'react-hook-form';
import { FaPlus } from 'react-icons/fa6';
import { HiOutlineTrash } from 'react-icons/hi';

import { ISignUpFields, IUser } from '@/@types/auth';
import { IAcademicHistory, IWorkExperiences } from '@/@types/profile';
import Container from '@/components/Container';
import DatePickerField from '@/components/DatePickerField';
import FormField from '@/components/FormField';
import { Typography } from '@/components/Typography';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/seperator';
import { Textarea } from '@/components/ui/textarea';
import { INPUT_ERRORS } from '@/constants';
import { WORK_EXPERIENCE_FIELDS } from '@/constants/profile';
import { useGenericMutation } from '@/hooks/useMutationData';
import { cn } from '@/lib/utils';
import { strings } from '@/locales';
import { useAuth } from '@/provider/AuthProvider';
import { useFormContext } from '@/provider/FormProvider/index';
import { workExperiencesStep } from '@/services/profile';
import { hasAnyValue } from '@/utils/common';

interface IWorkExperiencesProp {
  watch?: UseFormWatch<IAcademicHistory | ISignUpFields>;
  control?: Control<IAcademicHistory | ISignUpFields>;
  errors?: FieldErrors<IAcademicHistory | ISignUpFields>;
  isSignUpScreen?: boolean;
}

const WorkExperience: React.FC<IWorkExperiencesProp> = ({
  isSignUpScreen,
  watch: signUpFormWatch,
  control: signUpFormControl,
  errors: signUpFormErrors,
}) => {
  const { profile: staticText, common } = strings;

  const { stepFour, handleBack, handleSkip, setData, handleNext } =
    useFormContext();
  const { user, setUser } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const hasInitialized = useRef(false);
  const [showError, setShowError] = useState(false);

  const newSectionRef = useRef<HTMLDivElement | null>(null);
  const userWorkExperiences = {
    workExperiences: user?.workExperiences || [],
  } as IWorkExperiences;

  const {
    control,
    watch,
    formState: { errors, isDirty, isSubmitted },
    handleSubmit,
  } = useForm<IWorkExperiences>({
    mode: 'all',
    defaultValues: userWorkExperiences || stepFour || { workExperiences: [] },
  });

  const controlName = isSignUpScreen ? signUpFormControl : control;
  const fieldName = isSignUpScreen
    ? 'additionalInfo.workExperiences'
    : 'workExperiences';

  const { fields, append, remove, replace } = useFieldArray<
    IWorkExperiences,
    'workExperiences',
    'id'
  >({
    control: controlName as Control<IWorkExperiences>, // Explicitly cast the type
    name: fieldName as 'workExperiences',
  });

  const addNewSection = () => {
    append({
      nameOfCompany: '',
      position: '',
      salary: null,
      startDate: '',
      endDate: '',
      reasonForLeaving: '',
    });
    setTimeout(() => {
      if (newSectionRef.current) {
        newSectionRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 100);
  };

  const deleteSection = (index: number) => {
    remove(index);
  };

  const workExperienceMutation = useGenericMutation<
    IWorkExperiences,
    IUser | boolean
  >(workExperiencesStep, {
    onSuccess: (result) => {
      if (typeof result !== 'boolean') {
        setData({
          step: 4,
          data: result?.workExperiences as IWorkExperiences,
        });
        setUser({ ...user, workExperiences: result?.workExperiences } as IUser);
        handleNext();
      }
    },
  });

  const onSubmit: SubmitHandler<IWorkExperiences> = (data) => {
    if (isDirty) {
      workExperienceMutation.mutate(data);
    } else {
      setShowError(true);
      handleNext();
    }
  };

  useEffect(() => {
    if (!hasInitialized.current && fields.length === 0) {
      addNewSection();
      hasInitialized.current = true;
    }
  }, [fields, append]);

  useEffect(() => {
    const watchWorkExperiences = isSignUpScreen
      ? signUpFormWatch?.('additionalInfo.workExperiences')
      : watch('workExperiences');
    if (watchWorkExperiences?.length) {
      setShowError(false);
    }
  }, [
    watch('workExperiences'),
    signUpFormWatch?.('additionalInfo.workExperiences'),
    showError,
  ]);

  const handleEditOrDeleteButton = () => {
    if (isEdit) {
      replace(
        (user?.workExperiences ||
          stepFour?.workExperiences ||
          []) as IWorkExperiences['workExperiences'],
      );
    }
    setIsEdit(!isEdit);
  };

  return (
    <>
      <Container
        className={cn({
          'rounded-t-none border-t-0 p-4 w-[95%] mx-auto': !isSignUpScreen,
          'rounded-none p-0 md:p-0 border-none bg-transparent': isSignUpScreen,
        })}
      >
        <div
          className={cn({
            'mt-10 md:mt-20': !isSignUpScreen,
          })}
        >
          {!isSignUpScreen ? (
            <div className='flex justify-end'>
              {hasAnyValue(user?.workExperiences) ? (
                <Button
                  variant='link'
                  className={cn('w-auto h-auto p-1 text-base', {
                    'text-tomatoRed': isEdit,
                  })}
                  onClick={handleEditOrDeleteButton}
                >
                  {isEdit ? strings.common.cancel : strings.common.edit}
                </Button>
              ) : null}
            </div>
          ) : null}
          <div className='flex justify-between items-center'>
            <Typography
              variant='heading'
              className={cn('md:text-2xl', {
                'text-lg md:text-xl font-semibold': isSignUpScreen,
              })}
            >
              {staticText.workExperience}
            </Typography>
            {!hasAnyValue(user?.workExperiences) || isEdit ? (
              <Button
                className='text-sm px-1 md:px-1 w-fit h-fit py-0'
                variant='link'
                onClick={addNewSection}
                icon={<FaPlus />}
                iconPosition='left'
              >
                {staticText.buttons.addNew}
              </Button>
            ) : null}
          </div>
          {fields.map((field, sectionIndex) => (
            <div
              key={field.id}
              id={`section-${sectionIndex}`}
              ref={newSectionRef}
            >
              {sectionIndex !== 0 ? (
                <>
                  <Separator className='mt-5 mb-2' />
                  {!hasAnyValue(user?.workExperiences) || isEdit ? (
                    <div className='flex justify-end'>
                      <div onClick={() => deleteSection(sectionIndex)}>
                        <Typography className='text-tomatoRed mb-1 font-semibold cursor-pointer flex items-center gap-1'>
                          <HiOutlineTrash />
                          {staticText.buttons.delete}
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}
              <div
                className={cn('md:grid md:grid-cols-3 gap-x-4 mt-4', {
                  'md:grid-cols-2': isSignUpScreen,
                })}
              >
                {WORK_EXPERIENCE_FIELDS.map((fieldConfig, index) => (
                  <div key={`${fieldConfig.name}_${index}`}>
                    <Controller
                      control={
                        (isSignUpScreen
                          ? signUpFormControl
                          : control) as Control<
                          IWorkExperiences | ISignUpFields
                        >
                      }
                      rules={{
                        required: `*${fieldConfig.title} ${common.isRequired}`,
                        validate: (value) => {
                          const startDate = watch(
                            `workExperiences.${sectionIndex}.startDate`,
                          ) as string;
                          if (fieldConfig.name === 'endDate' && startDate) {
                            return (
                              new Date(value as string) > new Date(startDate) ||
                              INPUT_ERRORS.DATE.validate
                            );
                          }
                          return true;
                        },
                      }}
                      name={
                        `${isSignUpScreen ? 'additionalInfo.' : ''}workExperiences.${sectionIndex}.${fieldConfig.name}` as
                          | keyof IWorkExperiences
                          | keyof ISignUpFields
                      }
                      render={({ field: { onChange, value, name } }) => {
                        switch (true) {
                          case fieldConfig.isDate:
                            return (
                              <div>
                                <Typography className='flex capitalize font-bold md:text-base mb-3 mt-2'>
                                  {fieldConfig.title}
                                  <span className='text-redColor text-xl ml-1'>
                                    *
                                  </span>
                                </Typography>
                                <DatePickerField
                                  value={value as unknown as Date}
                                  setValue={onChange}
                                  contanierClassName='w-full rounded-md h-12 overflow-scroll'
                                  name={name}
                                  errors={
                                    isSignUpScreen ? signUpFormErrors : errors
                                  }
                                  disabled={
                                    !isEdit &&
                                    hasAnyValue(user?.workExperiences)
                                  }
                                />
                              </div>
                            );

                          default:
                            return (
                              <FormField
                                title={fieldConfig.title}
                                placeholder={common.typeHere}
                                name={name}
                                isRequired
                                value={value as unknown as string | number}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (fieldConfig.isNumber) {
                                    // Remove all non-numeric characters to ensure only numbers are kept
                                    let sanitizedValue = inputValue.replace(
                                      /\D/g,
                                      '',
                                    );
                                    // If the sanitized value has more than one digit, remove leading zeros
                                    if (sanitizedValue.length > 1) {
                                      sanitizedValue = sanitizedValue.replace(
                                        /^0+/,
                                        '',
                                      );
                                    }
                                    if (sanitizedValue.length <= 20) {
                                      onChange(sanitizedValue);
                                    }
                                  } else {
                                    onChange(inputValue);
                                  }
                                }}
                                errors={
                                  isSignUpScreen ? signUpFormErrors : errors
                                }
                                labelClassName='font-bold md:text-base mb-3'
                                disabled={
                                  !isEdit && hasAnyValue(user?.workExperiences)
                                }
                              />
                            );
                        }
                      }}
                    />
                    {showError &&
                      fieldConfig.name === 'nameOfCompany' &&
                      isSubmitted && (
                        <p className='text-redColor text-xs'>
                          {staticText.requiredError}
                        </p>
                      )}
                  </div>
                ))}
                {!isSignUpScreen ? (
                  <div className='col-span-3 mt-2'>
                    <Typography className='flex capitalize font-bold md:text-base mb-3'>
                      {staticText.reasonForLeaving}
                      <span className='text-redColor text-xl ml-1'>*</span>
                    </Typography>
                    <Controller
                      control={
                        (isSignUpScreen
                          ? signUpFormControl
                          : control) as Control<IWorkExperiences>
                      }
                      name={
                        `workExperiences.${sectionIndex}.reasonForLeaving` as const
                      }
                      rules={{
                        required: `*${staticText.reasonForLeaving} is required.`,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <Textarea
                          rows={4}
                          value={value as string}
                          onChange={onChange}
                          errors={isSignUpScreen ? signUpFormErrors : errors}
                          name={`workExperiences.${sectionIndex}.reasonForLeaving`}
                          placeholder={common.typeHere}
                          disabled={
                            !isEdit && hasAnyValue(user?.workExperiences)
                          }
                          className='border-greyWhite rounded-xl resize-none w-full placeholder:text-quickSilver placeholder:text-sm text-sm md:text-sm font-medium min-h-10 text-primaryBlack max-h-40 mt-1 p-2'
                        />
                      )}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </Container>
      {!isSignUpScreen ? (
        <div className='w-[95%] mx-auto py-3 flex justify-between'>
          <div onClick={() => handleSkip(4)}>
            <Typography className='text-primary underline font-semibold cursor-pointer'>
              {staticText.buttons.skip}
            </Typography>
          </div>
          <div className='flex gap-3'>
            <Button
              variant='outline'
              className='md:p-3 lg:p-5 focus-visible:ring-0 sm:w-44 md:w-32 font-medium sm:text-base !ring-offset-0'
              onClick={handleBack}
              disabled={workExperienceMutation.status === 'pending'}
            >
              {staticText.buttons.back}
            </Button>
            <Button
              className='sm:w-44 md:w-32'
              onClick={handleSubmit(onSubmit)}
              disabled={
                workExperienceMutation.status === 'pending' ||
                (!isDirty && isEdit)
              }
              loading={workExperienceMutation.status === 'pending'}
            >
              {isEdit ? staticText.buttons.update : staticText.buttons.next}
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WorkExperience;
