import { IUser } from '@/@types/auth';
import {
  IAcademicHistory,
  IFinalStep,
  IPersonalInfo,
  ISpecialSkills,
  ITrainingSeminar,
  IWorkExperiences,
} from '@/@types/profile';

export type setDataType =
  | { step: 1; data: IPersonalInfo }
  | { step: 2; data: IAcademicHistory }
  | { step: 3; data: ITrainingSeminar }
  | { step: 4; data: IWorkExperiences }
  | { step: 5; data: ISpecialSkills }
  | { step: 6; data: IFinalStep };

export type FormState = {
  currentStep: number;
  handleNext: () => void;
  handleBack: () => void;
  handleSkip: (stepNumber: number) => void;
  skippedSteps: number[];
  stepOne: IPersonalInfo | null;
  stepTwo: IAcademicHistory | null;
  stepThree: ITrainingSeminar | null;
  stepFour: IWorkExperiences | null;
  stepFive: ISpecialSkills | null;
  stepSix: IFinalStep | null;
  setData: ({ step, data }: setDataType) => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  profileData?: boolean | IUser | null;
  profileLoading?: boolean | null;
  stepsCompleted?: null;
};

const personalInfo: IPersonalInfo = {
  avatar: null,
  resume: null,
  positionDesired: '',
  expectedSalary: null,
  nickname: '',
  contactNo: '',
  presentAddress: '',
  civilStatus: '',
  permanentAddress: '',
  block: '',
  sex: '',
  birthPlace: '',
  birthDate: '',
  age: null,
  nationality: '',
  height: 0,
  weight: 0,
  religion: '',
  sssNo: '',
  tin: '',
  philHealthNo: '',
  hdmfNo: '',
  nameOfSpouse: '',
  occupationOfSpouse: '',
  nameOfMother: '',
  occupationOfMother: '',
  nameOfFather: '',
  occupationOfFather: '',
  distinguishMarksFeatures: '',
  city: '',
  country: '',
  zipCode: 0,
  source: '',
  sourceType: '',
  department: '',
};

export const academicHistory: IAcademicHistory = {
  college: '',
  collegeYearCovered: '',
  collegeCourse: '',
  vocational: '',
  vocationalYearCovered: '',
  vocationalCourse: '',
  highSchool: '',
  highSchoolYearCovered: '',
  highSchoolCourse: '',
  elementary: '',
  elementaryYearCovered: '',
  elementaryCourse: '',
};

const trainingSeminar: ITrainingSeminar = {
  trainingSeminars: [
    {
      courseTitle: '',
      inclusiveDates: '',
      venue: '',
    },
  ],
};

const workExperiences: IWorkExperiences = {
  workExperiences: [
    {
      nameOfCompany: '',
      position: '',
      salary: null,
      startDate: '',
      endDate: '',
      reasonForLeaving: '',
    },
  ],
};

const specialSkills: ISpecialSkills = {
  specialSkills: '',
};

const finalStep: IFinalStep = {
  relation: [
    {
      name: '',
      relationship: '',
    },
  ],
  chargedCourt: '',
  emergency: [
    {
      name: '',
      relationship: '',
      address: '',
      telNo: '',
    },
  ],
  chararacterReferences: [
    { name: '', relationship: '', address: '', contactNo: '' },
  ],
};

export const DEFAULT_STATE: FormState = {
  currentStep: 0,
  handleNext: () => {},
  handleBack: () => {},
  handleSkip: () => {},
  skippedSteps: [],
  stepOne: personalInfo,
  stepTwo: academicHistory,
  stepThree: trainingSeminar,
  stepFour: workExperiences,
  stepFive: specialSkills,
  stepSix: finalStep,
  setData: () => {},
  setCurrentStep: () => {},
  profileData: null,
  profileLoading: null,
};
