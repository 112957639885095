'use client';

import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FaInfoCircle } from 'react-icons/fa';
import { IoCloseOutline } from 'react-icons/io5';

import { cn } from '@/lib/utils';

interface ApplyJobToastProps {
  message: string;
  duration?: number;
  onClose?: () => void;
}

const ApplyJobToast: React.FC<ApplyJobToastProps> = ({
  message,
  duration = 5000,
  onClose,
}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose?.();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const handleClose = () => {
    setVisible(false);
    onClose?.();
  };

  if (!visible) return null;

  return createPortal(
    <div>
      <div
        className={cn(
          'toast toast-info relative flex gap-2 max-w-xs md:max-w-sm md:px-4 md:py-3 rounded-lg',
        )}
      >
        <IoCloseOutline
          className='absolute right-2 top-2 cursor-pointer'
          onClick={handleClose}
        />
        <FaInfoCircle className='text-blue w-6 h-5 mt-4 ml-2 md:ml-0' />
        <div className='toast-content-wrapper'>
          <p
            className='text-sm font-medium text-gray-900 my-4 w-[95%]'
            dangerouslySetInnerHTML={{ __html: message }}
          />
          <div
            className='toast-progress absolute bottom-0 left-0 h-1 bg-blue-500 rounded-b-md'
            style={{ animation: `progress ${duration / 1000}s linear` }}
          ></div>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default ApplyJobToast;
