import React, { useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { IDocuments, IUploadDocument } from '@/@types/dashboard';
import FileDropContainer from '@/components/FileDropContainer';
import Modal from '@/components/Modal';
import { Typography } from '@/components/Typography';
import { AcceptedFileFormats, INPUT_ERRORS } from '@/constants';
import { useGenericMutation } from '@/hooks/useMutationData';
import { strings } from '@/locales';
import { uploadDocument } from '@/services/dashboard';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

interface IAddCandidatesModalProps {
  open: boolean;
  handleCloseModal: () => void;
  fileName: string;
  refetch: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<boolean | IDocuments[], unknown>>;
}
const UploadFileModal: React.FC<IAddCandidatesModalProps> = ({
  open,
  handleCloseModal,
  fileName,
  refetch,
}) => {
  const staticText = strings.uploadFileModal;
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<IUploadDocument>({
    defaultValues: { file: null },
    mode: 'all',
  });

  const uploadedFile = watch('file');
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleProgressUpdate = useCallback((percentage: number) => {
    setUploadProgress(percentage);
  }, []);

  const uploadDocumentationMutation = useGenericMutation<
    IUploadDocument,
    boolean
  >(uploadDocument, {
    onSuccess: (response) => {
      if (response) {
        setUploadProgress(0);
        handleCloseModal();
        refetch();
        reset();
      }
    },
  });

  const handleUploadDocuments: SubmitHandler<IUploadDocument> = (formData) => {
    if (formData.file) {
      setUploadProgress(0);
      uploadDocumentationMutation.mutate({
        ...formData,
        name: fileName,
        setPercent: handleProgressUpdate,
      });
    }
  };

  return (
    <Modal
      open={open}
      handleCloseModal={() => {
        handleCloseModal();
        reset();
      }}
      title={staticText.title}
      primaryBtnLabel={staticText.upload}
      primaryBtnAction={handleSubmit(handleUploadDocuments)}
      buttonDisabled={
        uploadDocumentationMutation.status === 'pending' ||
        uploadedFile === null
      }
      isLoading={uploadDocumentationMutation.status === 'pending'}
    >
      <div className='w-full space-y-5'>
        <div>
          <Typography className='font-extrabold md:text-[15px]'>
            {staticText.uploadFile}
            <span className='text-redColor ml-1'>*</span>
          </Typography>
          <Controller
            name='file'
            rules={{
              required: INPUT_ERRORS.FILE.required,
            }}
            control={control}
            render={({ field: { onChange } }) => (
              <FileDropContainer
                buttonText={staticText.uploadFile}
                setFile={onChange}
                acceptedFileFormat={AcceptedFileFormats}
                iconClassName=' bg-primary rounded-full text-white text-[40px] p-2'
                fileName={
                  typeof uploadedFile === 'object' ? uploadedFile?.name : ''
                }
                progress={uploadProgress}
              />
            )}
          />
          {errors.file ? (
            <span className='text-redColor text-sm mt-1'>
              {errors.file.message}
            </span>
          ) : null}
        </div>
      </div>
    </Modal>
  );
};

export default UploadFileModal;
