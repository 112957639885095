import { IUser } from './auth';
import { ICandidate } from './candidate';
import { ITicket } from './chat';

export interface IAPIResponse<T> {
  status: boolean | number;
  message: string;
  data: T | null;
  error?: string;
}

export interface IDropdownOption {
  label: string;
  value: string;
  avatar?: string;
  icon?: React.ReactNode;
  extra?: boolean;
}

export enum FieldType {
  DatePicker = 'Date Picker',
  Dropdown = 'Dropdown',
  Input = 'Input',
  Range = 'Range',
  PhoneNumber = 'Phone Number',
}

export type OptionalProps<T> = {
  [K in keyof T as T extends { [P in K]: T[K] | undefined } ? K : never]?: T[K];
};

export type variantType =
  | 'green'
  | 'red'
  | 'blue'
  | 'yellow'
  | 'gray'
  | 'purple'
  | 'pink'
  | 'orange'
  | 'brown'
  | 'teal';

export interface IPaginationResponse<T> {
  results: T[];
  searchParams?: T;
  totalPages: number;
  totalJobs?: number;
}

export type EnumType<T> = {
  [key in keyof T]: T[key] extends string | number ? T[key] : never;
};

export interface INameable {
  firstName?: string;
  lastName?: string;
}

export interface IActiveChat {
  ticket?: ITicket | null;
  user: ICandidate | IUser;
}
